import { Link, useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import constants from '../../utils/constants';
import Divider from '../common/divider';

export default () => {

    const data = useStaticQuery(graphql`
    query Footer {
      logo: file(relativePath: {eq: "logo-white.png"}) {
        childImageSharp {
          fluid(maxHeight: 25) {
            src
          }
        }
      }
      foodpanda: file(relativePath: {eq: "glovo.png"}) {
        childImageSharp {
          fluid(maxHeight: 35) {
            src
          }
        }
      }
      takeaway: file(relativePath: {eq: "takeaway.png"}) {
        childImageSharp {
          fluid(maxHeight: 35) {
            src
          }
        }
      }
    }  
  `);

    const logo = data.logo.childImageSharp.fluid.src;
    const foodpanda = data.foodpanda.childImageSharp.fluid.src;
    const takeaway = data.takeaway.childImageSharp.fluid.src;

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <>
            <section id="footer">
                <footer>
                    <div className="upper-footer py-5">
                        <Container className="text-center">
                            <Row className="d-flex justify-content-center">
                                <Col lg={8}>
                                    <div>
                                        <h3 className='text-white'>{constants.FOOTER.TEXT}</h3>
                                        <Divider addClassName="gold" />
                                        <div>
                                            <Image fluid src={logo} alt="Арно Бистро лого" className="mt-3" />
                                        </div>
                                    </div>
                                </Col>

                                <Col xs={12} className="mt-4 mb-2 links">
                                    {constants.FOOTER.LINKS.map((route, index) => (
                                        <>
                                            {index !== 0 && <>&nbsp;•&nbsp;</>}<Link to={route.to} className="gold-link">{route.text}</Link>
                                        </>
                                    ))}
                                </Col>

                                <Col xs={12} className="mb-4 links">
                                    {constants.FOOTER.LINKS_ROW_2.map((route, index) => (
                                        <>
                                            {index !== 0 && <>&nbsp;•&nbsp;</>}<Link to={route.to} className="gold-link">{route.text}</Link>
                                        </>
                                    ))}
                                </Col>

                                <Col xs={12} className="mb-4">
                                    <div className="d-block d-sm-inline mb-4">
                                        <a href={constants.FOOTER.FACEBOOK} target="_blank" rel="noreferrer">
                                            <svg className="gold" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24"><path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z" /></svg>
                                        </a>
                                    </div>

                                    <a href={constants.FOOTER.FOODPANDA} target="_blank" rel="noreferrer">
                                        <Image fluid src={foodpanda} alt="Арно Бистро лого" className="ml-3 ml-sm-5" />
                                    </a>
                                    <a href={constants.FOOTER.TAKEAWAY} target="_blank" rel="noreferrer">
                                        <Image fluid src={takeaway} alt="Арно Бистро лого" className="ml-3 ml-sm-5" />
                                    </a>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="arrowed-section">
                        <div className="bump up" onClick={scrollToTop} role="button">
                            <svg className="bi bi-chevron-double-up arrow" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M7.646 2.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 3.707 2.354 9.354a.5.5 0 1 1-.708-.708l6-6z" />
                                <path fill-rule="evenodd" d="M7.646 6.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 7.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                            </svg>
                        </div>
                        <div className="lower-footer">
                            <Container>
                                <p className="text-center mb-0 dark-bg">
                                    © {constants.TITLE} {new Date().getFullYear()} • <a href={`${constants.GOOGLE_MAPS_LINK}`} target="_blank" rel="noreferrer">{constants.ADDRESS}</a> • <a href={`tel:${constants.TELEPHONE}`}>{constants.TELEPHONE}</a> • <p>Работно време за доставки - <b>Понеделник-Неделя</b> - 11:00 - 23:30</p>
                                    <br /><br />
                                    Изработено от <a href="https://www.marketiseme.com" target="_blank" rel="noreferrer">Marketise Me</a>
                                </p>
                            </Container>
                        </div>
                    </div>
                </footer>
            </section>
        </>
    )
}