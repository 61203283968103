/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import constants from "../../../utils/constants"
import CookieBanner from "../../common/CookieBanner"
import Footer from '../footer'
import Header from "../header/header"
import SEO from "../seo"

const Layout = ({ children, title, description }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
        site {
            siteMetadata {
            title
            }
        }
        }
    `);

    return (
        <>
            <SEO title={title || data.site.siteMetadata?.title || constants.TITLE} description={description} />
            <Header />
            <main>{children}</main>

            <CookieBanner />
            <Footer />
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
