import classnames from 'classnames';
import { graphql, Link, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Container, Image, Navbar } from "react-bootstrap";
import HamburgerMenu from "react-hamburger-menu";
import constants from "../../../utils/constants";
import { useLocation } from '@reach/router';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();

  const data = useStaticQuery(graphql`
    query Header {
      logo: file(relativePath: {eq: "logo.png"}) {
        childImageSharp {
          fluid(maxHeight: 25, quality: 100) {
            src
          }
        }
      }
    }  
  `);

  const logo = data.logo.childImageSharp.fluid.src;

  const isRouteActive = (route) => {
    return route === location.pathname;
  }

  return (
    <>
      <Navbar expand="lg" variant="light" bg="light" sticky="top" className="custom">
        <Container className="d-flex align-items-center">
          <HamburgerMenu
            className="d-lg-none"
            isOpen={isOpen}
            menuClicked={() => { setIsOpen(() => { return !isOpen }) }}
            width={18}
            height={15}
            strokeWidth={1}
            rotate={0}
            color='black'
            borderRadius={0}
            animationDuration={0.5}
          />
          <Navbar.Brand className="order-1 order-lg-0">
            <Link to="/" className="logo-text">
              <Image fluid src={logo} alt="Арно Бистро" />
            </Link>
          </Navbar.Brand>


          <Navbar.Collapse className={classnames("centered-links justify-content-center order-3 order-lg-2 pt-4 pb-3 py-lg-0 text-center", { 'show': isOpen })}>
            {constants.ROUTES.map((route) => (
              <Link to={route.to} className={classnames("nav-link custom mb-3 mb-lg-0", { "active": isRouteActive(route.to) })}><span>{route.text}</span></Link>
            ))}
          </Navbar.Collapse>

          <div className="phone-wrapper order-2 order-lg-2">
            <a href={`tel:${constants.TELEPHONE}`} className='btn btn-success btn-sm d-flex align-items-center header-btn'>
              <svg width="18px" height="18px" viewBox="0 0 16 16" className="bi bi-telephone-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M2.267.98a1.636 1.636 0 0 1 2.448.152l1.681 2.162c.309.396.418.913.296 1.4l-.513 2.053a.636.636 0 0 0 .167.604L8.65 9.654a.636.636 0 0 0 .604.167l2.052-.513a1.636 1.636 0 0 1 1.401.296l2.162 1.681c.777.604.849 1.753.153 2.448l-.97.97c-.693.693-1.73.998-2.697.658a17.47 17.47 0 0 1-6.571-4.144A17.47 17.47 0 0 1 .639 4.646c-.34-.967-.035-2.004.658-2.698l.97-.969z" />
              </svg>
              <span className="ml-3 d-none d-lg-inline">Поръчай</span>
            </a>
          </div>
        </Container>
      </Navbar>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header;
