export default {
    META: {
        TITLE: "Арно Бистро - Автентична Българска Кухня",
        DESCRIPTION: "Изключително качество на храната и висок стандарт на обслужване, най-добрият български ресторант Арно Бистро - автентична българска кухня"
    },

    TITLE: 'Арно Бистро',
    TELEPHONE: '+359 (0)2 417 9457',
    GOOGLE_MAPS_LINK: "https://goo.gl/maps/qQEidf63L6ePJMbN7",
    ADDRESS: 'ул. "Костенски водопад" 57, 1404 София',
    ROUTES: [
        {
            to: '/',
            text: 'Начало'
        },
        {
            to: '/za-nas',
            text: 'За нас'
        },
        {
            to: '/menu',
            text: 'Меню'
        },
        {
            to: '/kontakti',
            text: 'Контакти'
        },
    ],

    FOOTER: {
        TEXT: "Потопете се в света на българската национална кухня",
        LINKS: [
            {
                to: '/',
                text: 'Начало'
            },
            {
                to: '/za-nas',
                text: 'За нас'
            },
            {
                to: '/menu',
                text: 'Меню'
            },
            {
                to: '/kontakti',
                text: 'Контакти'
            },
        ],

        LINKS_ROW_2: [
            {
                to: '/obshti-usloviq',
                text: 'Общи условия'
            },
            {
                to: '/cookie-policy',
                text: 'Политика за бисквитките'
            },
            {
                to: '/lichni-danni',
                text: 'Защита на личните данни'
            }
        ],
        FACEBOOK: "https://www.facebook.com/BistroAriana",
        FOODPANDA: "https://www.foodpanda.bg/en/restaurant/y8xb/bistro-ariana?r=1#",
        TAKEAWAY: "https://www.takeaway.com/bg/menu/ariana-bistrobistro-ariana"
    },

    INDEX: {
        META: {
            TITLE: "Автентична Българска Кухня",
            DESCRIPTION: "Изключително качество на храната и висок стандарт на обслужване, най-добрият български ресторант Арно Бистро - автентична българска кухня"
        }
    },

    MENU: {
        META: {
            TITLE: "Меню",
            DESCRIPTION: "Изключително качество на храната и висок стандарт на обслужване, най-добрият български ресторант Арно Бистро - автентична българска кухня"
        }
    },

    ABOUT: {
        META: {
            TITLE: "За нас",
            DESCRIPTION: "Изключително качество на храната и висок стандарт на обслужване, най-добрият български ресторант Арно Бистро - автентична българска кухня"
        }
    },

    CONTACT: {
        META: {
            TITLE: "Контакти",
            DESCRIPTION: "Изключително качество на храната и висок стандарт на обслужване, най-добрият български ресторант Арно Бистро - автентична българска кухня"
        }
    }


}